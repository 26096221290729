<template>
  <div class="page_connection" id="connection_list">
    <div class="connection">
      <div class="lxwm_banner"></div>
      <div class="lxwm_content">
        <div class="lxwm_container">
          <div class="piece1" style="cursor: pointer;" @click="to_form('/consultation_information/edit')"></div>
          <div class="piece2" style="cursor: pointer;" @click="to_form('/consultation_information/edit')"></div>
          <div class="piece3" style="cursor: pointer;" @click="to_form('/consultation_information/edit')"></div>
          <div class="piece4" style="cursor: pointer;" @click="to_form('/consultation_information/edit')"></div>
          <div class="piece5" style="cursor: pointer;" @click="to_form('/consultation_information/edit')"></div>
          <div class="piece6" style="cursor: pointer;" @click="to_form('/consultation_information/edit')"></div>
        </div>
      </div>
      <div class="gdqd">
      </div>
    </div>
    <div class="jmly">
      <router-link to="/consultation_information/edit">
        <div class="jmly_to"></div>
      </router-link>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/page.js";
import edit from "@/views/consultation_information/edit.vue";

export default {
  mixins: [mixin],
  components: {
    edit,
  },
  data() {
    return {};
  },
  created() {
  },
  methods: {
    goAnchor(selector) {
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth"
      });
    }
  },
};
</script>

<style scoped>
.connection {
  width: 100%;
}

.lxwm_banner {
  width: 100%;
  height: 100vh;
  background: url("../../assets/images/lxwm.jpg") no-repeat;
  background-size: 100% 100%;
}

.lxwm_content {
  width: 100%;
  min-height: 100vh;
  background-color: #FFFFFF;
}

.lxwm_container {
  width: 80%;
  min-height: 100vh;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.lxwm_container > div {
  margin-right: -40px;
}

.piece1 {
  width: 33%;
  min-height: 45vh;
  margin-top: 10px;
  margin-bottom: 10px;
  background: url("../../assets/images/lxwm/jmhz.png");
  background-size: 100% 100%;
}

.piece2 {
  width: 33%;
  min-height: 45vh;
  margin-top: 10px;
  margin-bottom: 10px;
  background: url("../../assets/images/lxwm/hyhz.png");
  background-size: 100% 100%;
}

.piece3 {
  width: 33%;
  min-height: 45vh;
  margin-top: 10px;
  margin-bottom: 10px;
  background: url("../../assets/images/lxwm/gyhz.png");
  background-size: 100% 100%;
}

.piece4 {
  width: 33%;
  min-height: 45vh;
  margin-top: 10px;
  margin-bottom: 10px;
  background: url("../../assets/images/lxwm/yyhz.png");
  background-size: 100% 100%;
}

.piece5 {
  width: 33%;
  min-height: 45vh;
  margin-top: 10px;
  margin-bottom: 10px;
  background: url("../../assets/images/lxwm/hdyjl.png");
  background-size: 100% 100%;
}

.piece6 {
  width: 33%;
  min-height: 45vh;
  margin-top: 10px;
  margin-bottom: 10px;
  background: url("../../assets/images/lxwm/qtxq.png");
  background-size: 100% 100%;
}

.jmly {
  height: 68px !important;
  width: 130px;
  z-index: 11111112;
  top: 20%;
  right: 0px;
  position: fixed;
  margin-top: -34px;
  margin-left: 0px;
}

.jmly_to {
  height: 100%;
  width: 100%;
  background: url("../../assets/images/join/hzzx.png") no-repeat;
  background-size: 100% 100%;
}
.gdqd {
  width: 100%;
  height: 90vh;
  background: url("../../assets/images/lxwm/gdqd.jpg") no-repeat;
  background-size: 100% 100%;
}
@media (max-width: 1081px) {

  .jmly_to {
    height: 50%;
    width: 50%;
    background: url("../../assets/images/join/hzzx.png") no-repeat;
    background-size: 100% 100%;
  }

  .jmly {
    height: 68px !important;
    width: 130px;
    z-index: 11111112;
    top: 20%;
    right: -45px;
    position: fixed;
    margin-top: -34px;
    margin-left: 0px;
  }
  .lxwm_banner {
    width: 100%;
    height: 40vh;
    background: url("../../assets/images/lxwm.jpg") no-repeat;
    background-size: 100% 100%;
  }
  .piece1 {
    width: 100%;
    min-height: 45vh;
    margin-top: 10px;
    margin-bottom: 10px;
    background: url("../../assets/images/lxwm/jmhz.png");
    background-size: 100% 100%;
  }

  .piece2 {
    width: 100%;
    min-height: 45vh;
    margin-top: 10px;
    margin-bottom: 10px;
    background: url("../../assets/images/lxwm/hyhz.png");
    background-size: 100% 100%;
  }

  .piece3 {
    width: 100%;
    min-height: 45vh;
    margin-top: 10px;
    margin-bottom: 10px;
    background: url("../../assets/images/lxwm/gyhz.png");
    background-size: 100% 100%;
  }

  .piece4 {
    width: 100%;
    min-height: 45vh;
    margin-top: 10px;
    margin-bottom: 10px;
    background: url("../../assets/images/lxwm/yyhz.png");
    background-size: 100% 100%;
  }

  .piece5 {
    width: 100%;
    min-height: 45vh;
    margin-top: 10px;
    margin-bottom: 10px;
    background: url("../../assets/images/lxwm/hdyjl.png");
    background-size: 100% 100%;
  }

  .piece6 {
    width: 100%;
    min-height: 45vh;
    margin-top: 10px;
    margin-bottom: 10px;
    background: url("../../assets/images/lxwm/qtxq.png");
    background-size: 100% 100%;
  }
  .gdqd {
    width: 100%;
    height: 25vh;
    background: url("../../assets/images/lxwm/gdqd.jpg") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
